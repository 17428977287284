import type { NextPage } from 'next'
import Head from 'next/head'
import { Headline } from '@/components/common/Headline'
import { Button } from '@/components/controls/Button'
import { PlanetDecor } from '@/components/decors/PlanetDecor'
import { useQuery } from '@/utils/url'

const Custom404: NextPage = () => {
  return (
    <>
      <Head>
        <title>Page Not Found | Commit Action</title>
        <meta name="description" content="It seems this Page is somewhere far away. Cloud hidden, whereabouts unknown." />
      </Head>
      <div className="flex container page-404-container min-h-[640px]">
        <Headline marginBottomOnly={true} className="!mb-0" textClassName="!text-[120px] lg:!text-[200px]">404</Headline>
        <p className="font-semibold text-[20px] lg:text-[26px] mt-8 mb-4 text-center font--accent">
          It seems this Page is somewhere far away.<br className="hidden md:block"/>
          Cloud hidden, whereabouts unknown.
        </p>
        <p className="text-[20px]">Time to go back home!</p>
        <Button className="mt-14" type="primary" href={useQuery('/')}>To Home Page</Button>
      </div>
      <div className="absolute bottom-[0] h-[900px] left-0 w-full overflow-hidden pointer-events-none">
        <PlanetDecor className="h-[900px] larger:h-[1200px]"/>
      </div>
    </>
  )
}

// @ts-ignore
Custom404.getClass = () => {
  return 'page-404'
}

export default Custom404
