import styles from '@/styles/components/common/Headline.module.scss'

interface Props {
  tag?: keyof JSX.IntrinsicElements,
  marginBottomOnly?: boolean
  noMargins?: boolean,
  textClassName?: string
}

export const Headline: React.FC<Props & React.HTMLAttributes<HTMLOrSVGElement>> = ({ tag: Wrapper = 'h1', className, textClassName = '', marginBottomOnly, noMargins, children }) => {
  return (
    <div className={`
      ${className || ''}
      ${styles.headline}
      ${marginBottomOnly ? styles.headline_mb_only : ''}
      ${noMargins ? styles.headline_no_margin : ''}
    `}>
      <Wrapper className={`${styles['headline__' + Wrapper]} ${textClassName}`}>{children}</Wrapper>
    </div>
  )
}
